import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../../Layout/theme";

export const StyledAboutSchedule = styled.div`
  padding-bottom: 80px;

  .header {
    ${StyleFontHeader}
    font-size: 57px;
    line-height: 68px;
    letter-spacing: 0.06em;
  }

  .schedule {
    display: flex;
    align-items: flex-start;

    .season {
      text-align: center;
      flex: 1 1 0;

      &.active {
        .season-top {
          background-color: ${({ theme }) => theme.color_accent_dark};
        }

        .splits {
          background-color: ${({ theme }) => theme.color_accent_light};
        }
      }

      .season-top {
        background-color: ${({ theme }) => theme.middle_color_primary};
        padding: 20px;

        .season-title {
          ${StyleFontHeader}
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.02em;
        }

        .season-details {
          ${TextTransformUppercase}
          font-size: 16px;
          line-height: 23px;
        }
      }

      .splits {
        padding: 20px;
        background-color: ${({ theme }) => theme.color_background_tertiary};

        .split {
          ${StyleFontHeader}
          letter-spacing: 0.02em;
          padding: 10px 0;
          margin: 0 50px;

          &.past {
            .split-title {
              text-decoration-line: line-through;
            }
          }

          &.active {
            background-color: ${({ theme }) => theme.color_accent_lighter};
          }

          .split-title {
            font-size: 36px;
            line-height: 36px;
          }

          .split-dates {
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};

    .schedule {
      .season {
        &:not(:last-child) {
          margin-right: 15px;
        }

        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }

  @media ${device.small_and_medium} {
    .schedule {
      flex-direction: column;
      align-items: stretch;

      .season {
        margin: 20px 0;
        width: 100%;
      }
    }
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};
  }
`;
