import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../../Layout/theme";

export const StyledAboutPrizePool = styled.div`
  &.last-prize-pool {
    padding-bottom: 80px;
  }

  .header {
    ${StyleFontHeader}
    font-size: 57px;
    line-height: 68px;
    letter-spacing: 0.06em;
  }

  .place {
    display: flex;
    align-items: center;
    position: relative;

    .rank {
      ${TextTransformUppercase}
    }

    .amount {
      ${StyleFontHeader};
    }
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};
  }
`;

export const StyledLargeHeader = styled.div`
  .top-two {
    display: flex;
    align-items: stretch;

    .place {
      width: 50%;

      &.first {
        background-color: ${({ theme }) => theme.color_victory};
      }

      &.second {
        background-color: ${({ theme }) => theme.color_accent_dark};
      }

      .rank {
        font-size: 28px;
        font-weight: bold;
        margin: 0 50px;
      }

      .amount {
        font-size: 80px;
      }
    }
  }

  .rest {
    display: flex;
    align-items: stretch;
    padding: 15px 0;
    background-color: ${({ theme }) => theme.color_background_tertiary};
    border-bottom: 1px solid ${({ theme }) => theme.color_rule};

    .place {
      width: calc(100% / 3);

      .rank {
        margin: 0 30px 0 50px;
        font-weight: bold;
      }

      .amount {
        font-size: 50px;
      }
    }
  }

  @media ${device.large} {
    .rest {
      .place {
        &:not(:first-child) {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 2px;
            top: 10%;
            bottom: 10%;
            background-color: ${({ theme }) => theme.color_rule};
          }
        }
      }
    }
  }

  @media ${device.small_and_medium} {
    .top-two,
    .rest {
      flex-direction: column;

      .place {
        width: 100%;
        
        .rank {
          margin: 0 30px;
        }
      }
    }
  }
`;

export const StyledMediumHeader = styled.div`
  .top-two {
    display: flex;
    align-items: stretch;
    background-color: ${({ theme }) => theme.color_background_tertiary};
    border-bottom: 1px solid ${({ theme }) => theme.color_rule};

    .place {
      width: 50%;

      &.first {
        color: ${({ theme }) => theme.color_victory};
      }

      &.second {
        color: ${({ theme }) => theme.color_accent};
      }

      .rank {
        font-size: 28px;
        font-weight: bold;
        margin: 0 50px;
      }

      .amount {
        font-size: 80px;
      }
    }
  }

  @media ${device.large} {
    .top-two {
      .place {
        &.first {
          &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 2px;
            top: 20%;
            bottom: 20%;
            background-color: ${({ theme }) => theme.color_rule};
          }
        }
      }
    }
  }

  @media ${device.small_and_medium} {
    .top-two {
      flex-direction: column;

      .place {
        width: 100%;

        .rank {
          margin: 0 30px;
        }
      }
    }
  }
`;

export const StyledBottomTiers = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color_background_tertiary};
  color: ${({ theme }) => theme.color_text_dimmed};
  font-size: 18px;
  align-items: stretch;
  position: relative;

  .column {
    width: calc(100% / 3);
    padding: 30px 50px;
    position: relative;

    .row {
      display: flex;
      align-items: center;
      padding: 3px 0;

      .rank {
        flex-grow: 0;
      }

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: ${({ theme }) => theme.color_rule};
        margin: 0 20px;
      }

      .amount {
        flex-grow: 0;
      }
    }
  }

  .bg-logo {
    display: none;
  }

  @media ${device.large} {
    
  }

  @media ${device.medium_and_large} {
    .column {
      &:not(:first-child) {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 2px;
          top: 20%;
          bottom: 20%;
          background-color: ${({ theme }) => theme.color_rule};
        }
      }
    }

    .bg-logo {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
  }

  @media ${device.medium} {
    .column {
      padding: 30px;
    }
  }

  @media ${device.small} {
    flex-direction: column;
    padding: 30px 0;

    .column {
      width: 100%;
      padding: 0 30px;
    }
  }
`;
