import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { sliceIntoChunks } from "../../../../helpers/utils";
import { useCurrencyFormatter } from "../../../../hooks/use-currency-format";
import { useGetRankWithSuffix } from "../../../../hooks/use-get-rank-with-suffix";
import { PrizePoolConfig, PrizePoolType } from "../../../../services/championsQueueData/index.type";
import { StyledAboutPrizePool, StyledBottomTiers, StyledLargeHeader, StyledMediumHeader } from "./style";

export type Props = {
  className?: string
  prizePool: PrizePoolConfig
};

const AboutPrizePool: React.FC<Props> = ({ className, prizePool }: Props) => {
  return (
    <StyledAboutPrizePool className={ className }>
      <div className="header">{prizePool.title}</div>
      {prizePool.type === "large" && <LargeHeader prizePool={prizePool} />}
      {prizePool.type === "medium" && <MediumHeader prizePool={prizePool} />}
      <BottomTiers prizePool={prizePool}/>
    </StyledAboutPrizePool>
  )
};

export default AboutPrizePool;

const bottomTierStartIndex: Record<PrizePoolType, number> = {
  "small": 0,
  "medium": 2,
  "large": 5
};

const bottomTierColumnSize: Record<PrizePoolType, number> = {
  "small": 4,
  "medium": 3,
  "large": 3
};

const LargeHeader: React.FC<Props> = ({ prizePool }: Props) => {
  const getRankWithSuffix = useGetRankWithSuffix();
  const formatCurrency = useCurrencyFormatter();

  const prizes = prizePool.prizes.slice(0, bottomTierStartIndex.large);
  const first = prizes[0];
  const second = prizes[1];
  // Should be 3rd, 4th, and 5th
  const theRest = prizes.slice(2);

  return (
    <StyledLargeHeader>
      <div className="top-two">
        <div className="place first">
          <div className="rank">{getRankWithSuffix(first.rank)}</div>
          <div className="amount">{formatCurrency(first.dollars)}</div>
        </div>
        <div className="place second">
          <div className="rank">{getRankWithSuffix(second.rank)}</div>
          <div className="amount">{formatCurrency(second.dollars)}</div>
        </div>
      </div>
      <div className="rest">
        {theRest.map((prize, i) => (
          <div key={i} className="place">
            <div className="rank">{getRankWithSuffix(prize.rank)}</div>
            <div className="amount">{formatCurrency(prize.dollars)}</div>
          </div>
        ))}
      </div>
    </StyledLargeHeader>
  );
};

const MediumHeader: React.FC<Props> = ({ prizePool }: Props) => {
  const getRankWithSuffix = useGetRankWithSuffix();
  const formatCurrency = useCurrencyFormatter();

  const first = prizePool.prizes[0];
  const second = prizePool.prizes[1];

  return (
    <StyledMediumHeader>
      <div className="top-two">
        <div className="place first">
          <div className="rank">{getRankWithSuffix(first.rank)}</div>
          <div className="amount">{formatCurrency(first.dollars)}</div>
        </div>
        <div className="place second">
          <div className="rank">{getRankWithSuffix(second.rank)}</div>
          <div className="amount">{formatCurrency(second.dollars)}</div>
        </div>
      </div>
    </StyledMediumHeader>
  );
};

const BottomTiers: React.FC<Props> = ({ prizePool }: Props) => {
  const { t } = useTranslation();
  const getRankWithSuffix = useGetRankWithSuffix();
  const formatCurrency = useCurrencyFormatter();

  const prizes = prizePool.prizes.slice(bottomTierStartIndex[prizePool.type]);
  const columns = sliceIntoChunks(prizes, bottomTierColumnSize[prizePool.type]);

  return (
    <StyledBottomTiers>
      {columns.map((column, i) => (
        <div key={i} className="column">
          {column.map((prize, j) => (
            <div key={j} className="row">
              <div className="rank">{getRankWithSuffix(prize.rank)}</div>
              <div className="line" />
              <div className="amount">{formatCurrency(prize.dollars)}</div>
            </div>
          ))}
        </div>
      ))}
      {columns.length < 3 && (
        <StaticImage
          className="bg-logo"
          src="../../../../images/prize-pool-bg-logo.png"
          alt={t("nav.logo")}
        />
      )}
    </StyledBottomTiers>
  );
};
