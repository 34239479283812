import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMemo } from "react";

export const useCurrencyFormatter = () => {
  const { i18n } = useTranslation();

  const formatter = useMemo(
    () => new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }),
    [i18n.language]
  );

  return (dollars: number) => formatter.format(dollars);
};
