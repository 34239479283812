import React from "react";
import { useTranslation } from "react-i18next";
import { StyledAboutExplainer } from "./style";

const AboutExplainer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAboutExplainer>
      <h2 className="title">{t("about:explainer.title")}</h2>
      <p className="message">{t("about:explainer.description")}</p>
    </StyledAboutExplainer>
  );
};

export default AboutExplainer;
