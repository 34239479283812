import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { cls } from "../../../../helpers/utils";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image-es5";
import {
  ScheduleConfig,
  ScheduleSeasonConfig,
  SplitPrizeConfig
} from "../../../../services/championsQueueData/index.type";
import { StyledAboutSchedule } from "./style";

export type Props = {
  schedule: ScheduleConfig
};

const AboutSchedule: React.FC<Props> = ({ schedule }: Props) => {
  const { t, i18n } = useTranslation();

  const { patternImage } = useStaticQuery(
    graphql`
      query {
        patternImage: file(relativePath: { eq: "schedule-active-pattern.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(patternImage);
  const bgImage = convertToBgImage(image);

  const dateFormat = useMemo(
    () => new Intl.DateTimeFormat(i18n.language, {
      month: "short",
      day: "numeric"
    }),
    [i18n.language]
  );

  const renderSplit = (split: SplitPrizeConfig) => (
    <div
      key={split.title}
      className={cls(
        "split",
        Date.now() >= Date.parse(split.closeDate) && "past",
        isActive(split) && "active"
      )}
    >
      <div className="split-title">{split.title}</div>
      <div className="split-dates">
        {t("about:dateRange", {
          from: dateFormat.format(Date.parse(split.openDate)),
          to: dateFormat.format(Date.parse(split.closeDateForDisplay))
        })}
      </div>
    </div>
  );

  const renderSeasonDetails = (season: ScheduleSeasonConfig) => {
    const isShowDates = season.splits.length > 1;
    const isShowPatchRange =
      season.patchUpper && season.patchUpper !== season.patchLower;

    return (
      <div className="season-details">
        {isShowDates && t("about:dateRange", {
          from: dateFormat.format(Date.parse(season.openDate)),
          to: dateFormat.format(Date.parse(season.closeDateForDisplay))
        })}
        {isShowDates && ` | `}
        {isShowPatchRange && t("about:patchRange", {
          from: season.patchLower,
          to: season.patchUpper
        })}
        {!isShowPatchRange && t("about:patch", { value: season.patchLower })}
      </div>
    );
  };

  const renderSeason = (season: ScheduleSeasonConfig) => (
    <div key={season.title} className={cls("season", isActive(season) && "active")}>
      <div className="season-top">
        <div className="season-title">{season.title}</div>
        {renderSeasonDetails(season)}
      </div>
      {isActive(season) && (
        <BackgroundImage Tag="div" className="splits" {...bgImage}>
          {season.splits.map(renderSplit)}
        </BackgroundImage>
      )}
      {!isActive(season) && (
        <div className="splits">{season.splits.map(renderSplit)}</div>
      )}
    </div>
  );

  return (
    <StyledAboutSchedule>
      <h2 className="header">{t("about:schedule", { year: schedule.year })}</h2>
      <div className="schedule">
        {schedule.seasons.map(renderSeason)}
      </div>
    </StyledAboutSchedule>
  );
};

type Range = {
  openDate: string
  closeDate: string
};

const isActive = (range: Range) => {
  const now = Date.now();
  const open = Date.parse(range.openDate);
  const close = Date.parse(range.closeDate);

  return now >= open && now < close;
};

export default AboutSchedule;
