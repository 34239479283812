import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

export const StyledAboutExplainer = styled.div`
  background-color: ${({ theme }) => theme.color_background_tertiary};
  text-align: center;

  .title {
    ${StyleFontHeader}
    font-size: 34px;
    letter-spacing: 0.07em;
  }

  .message {
    margin: 0 auto;
    max-width: 750px;
    font-size: 18px;
  }

  @media ${device.large} {
    margin: 60px ${({ theme }) => theme.gutter_size_large};
    padding: ${({ theme }) => theme.gutter_size_large};
  }

  @media ${device.medium} {
    margin: 60px ${({ theme }) => theme.gutter_size_medium};
    padding: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: 60px ${({ theme }) => theme.gutter_size_small};
    padding: ${({ theme }) => theme.gutter_size_small};
  }
`;
